var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu } from "../../../components/Menu";
import { translater } from "../../../../../utils/localization/localization";
import { API } from "../../../../../api";
import { CreateButton } from "./components/CreateButton";
import { UpdateButton } from "./components/UpdateButton";
import { RemoveButton } from "./components/RemoveButton";
import { successNotification } from "../../../../../redux/actions/notificationsActions";
export var NewsList = function () {
    var _a = __read(useState([]), 2), news = _a[0], setNews = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var resaveOrder = function (e) {
        setLoading(true);
        var ids = e.value.map(function (news) { return news.id; });
        console.log(ids);
        API.resaveNewsOrder(ids)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            loadNews();
        })
            .catch(function () { return setLoading(false); });
    };
    var loadNews = function () {
        setLoading(true);
        API.getNews()
            .then(function (response) {
            var data = response.data;
            setNews(data);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        loadNews();
    }, []);
    return (_jsxs("div", __assign({ className: "w-full flex flex-column gap-3" }, { children: [_jsx(Menu, {}), _jsx("div", __assign({ className: "flex gap-2" }, { children: _jsx(CreateButton, { loadNews: loadNews }) })), _jsxs(DataTable, __assign({ className: "w-full", reorderableRows: true, onRowReorder: resaveOrder, lazy: true, loading: loading, value: news, tableStyle: { minWidth: '50rem' } }, { children: [_jsx(Column, { rowReorder: true, className: "justify-content-center", style: { maxWidth: "90px" } }), _jsx(Column, { field: "title", header: "Title" }), _jsx(Column, { field: "date", header: "Date" }), _jsx(Column, { className: "justify-content-center", header: 'Content', body: function (row) {
                            return (_jsx(_Fragment, { children: _jsx("img", { style: { maxWidth: "100px" }, src: row.img, alt: "news-preview" }) }));
                        } }), _jsx(Column, { className: "justify-content-center", header: 'Preview', body: function (row) {
                            var text = row.content;
                            var maxLength = 30;
                            if (text.length <= maxLength) {
                                return text; // Возвращаем полный текст
                            }
                            return text.substring(0, maxLength) + '..';
                        } }), _jsx(Column, { className: "justify-content-center", header: translater("actionsColumn"), body: function (row) {
                            return (_jsxs("div", __assign({ className: "flex gap-2" }, { children: [_jsx(UpdateButton, { loadNews: loadNews, news: row }), _jsx(RemoveButton, { loadNews: loadNews, news: row })] })));
                        } }, 'actions')] }))] })));
};
