var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink } from "react-router-dom";
import { translater } from "../../../utils/localization/localization";
import '../../../assets/styles/scss/particles/header.scss';
export var Menu = function () {
    var menuItems = [
        {
            path: '/panel',
            title: translater("serverHeaderMenuPanel").toString()
        },
        {
            path: '/news-list',
            title: translater("serverHeaderMenuNewsList").toString()
        }
    ];
    return (_jsx("div", __assign({ className: "main-header" }, { children: _jsx("ul", __assign({ className: "menu flex m-0" }, { children: menuItems.map(function (item, index) {
                return (_jsx("li", { children: _jsx(NavLink, __assign({ to: item.path, className: "app-hover" }, { children: _jsx("span", __assign({ className: "font-medium app-header-color font-bold cursor" }, { children: item.title })) })) }, index));
            }) })) })));
};
