var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, useLocation } from 'react-router-dom';
import { images } from '../../../../assets/images/imgs';
import { Translater } from '../Translater';
import { Menu } from './components/Menu';
import '../../../../assets/styles/scss/particles/header.scss';
import { translater } from '../../../../utils/localization/localization';
import { useEffect, useState } from 'react';
import { classNames } from 'primereact/utils';
export var Header = function () {
    var _a = __read(useState(false), 2), translaterVisible = _a[0], setTranslaterVisible = _a[1];
    var location = useLocation();
    useEffect(function () {
        var pathname = location.pathname;
        setTranslaterVisible(!!['/support-for-ukrainian-students'].includes(pathname));
    }, [location]);
    return (_jsxs("header", __assign({ className: 'main-header app-width-limit mx-auto flex flex-column' }, { children: [_jsxs("div", __assign({ className: 'cover' }, { children: [_jsx(Link, __assign({ to: "/", className: 'logo app-hover' }, { children: _jsx("img", { src: images.logo, alt: "logo" }) })), _jsxs("ul", { children: [_jsx("li", __assign({ className: classNames('app-color-border app-hover', {
                                    'hidden': !translaterVisible
                                }), style: { background: "none" } }, { children: _jsx(Translater, {}) })), _jsx("li", __assign({ className: 'app-hover' }, { children: _jsx("a", __assign({ href: translater("socialMediaLinkInstagram").toString(), target: '_blank' }, { children: _jsx("img", { src: images.particals.header.insta, alt: "insta" }) })) })), _jsx("li", __assign({ className: 'app-hover' }, { children: _jsx("a", __assign({ href: translater("socialMediaLinkFacebook").toString(), target: '_blank' }, { children: _jsx("img", { src: images.particals.header.facebook, alt: "facebook" }) })) })), _jsx("li", __assign({ className: 'app-hover' }, { children: _jsx("a", __assign({ href: translater("socialMediaLinkLinkedin").toString(), target: '_blank' }, { children: _jsx("img", { src: images.particals.header.linkedin, alt: "linkedin" }) })) }))] })] })), _jsx(Menu, {})] })));
};
