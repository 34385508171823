var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { translater } from "../../../../../../utils/localization/localization";
import { images } from "../../../../../../assets/images/imgs";
export var PrivacyPolicy = function (_a) {
    var setPrivacyTerms = _a.setPrivacyTerms, privacyTerms = _a.privacyTerms, setInformationProcessing = _a.setInformationProcessing, informationProcessing = _a.informationProcessing;
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "policy-area" }, { children: [_jsx("div", __assign({ onClick: function () { return setPrivacyTerms(function (prev) { return ({ selected: !prev.selected, isTouched: true }); }); }, className: clsx({
                            "flag-area": true,
                            "error": privacyTerms.isTouched && !privacyTerms.selected
                        }) }, { children: privacyTerms.selected ? (_jsx("img", { src: images.home.check, alt: "check", className: "flag" })) : '' })), _jsxs("span", { children: [translater("introductionPageFormPrivacyPolicy"), _jsx(Link, __assign({ to: "/privacy-policy" }, { children: translater("introductionPageFormPrivacyPolicyLink") }))] })] })), _jsxs("div", __assign({ className: "policy-area" }, { children: [_jsx("div", __assign({ onClick: function () { return setInformationProcessing(function (prev) { return ({ selected: !prev.selected, isTouched: true }); }); }, className: clsx({
                            "flag-area": true,
                            "error": informationProcessing.isTouched && !informationProcessing.selected
                        }) }, { children: informationProcessing.selected ? (_jsx("img", { src: images.home.check, alt: "check", className: "flag" })) : '' })), _jsx("span", { children: translater("introductionPageFormInformationProcessing") })] }))] }));
};
