var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { useState } from "react";
import { successNotification } from "../../../../../redux/actions/notificationsActions";
import { API } from "../../../../../api";
import { translater } from "../../../../../utils/localization/localization";
import { NameField } from "./fields/Name";
import { SurnameField } from "./fields/Surname";
import { EmailField } from "./fields/Email";
import { QuestionField } from "./fields/Question";
import { PrivacyPolicy } from "./fields/PrivacyPolicy";
var defaultFormValues = {
    name: '',
    surname: '',
    email: '',
    question: '',
};
var defaultPrivacyTerms = { selected: false, isTouched: false };
var defaultInformationProcessing = { selected: false, isTouched: false };
export var Form = function (_a) {
    var hideModal = _a.hideModal;
    var _b = __read(useState(defaultPrivacyTerms), 2), privacyTerms = _b[0], setPrivacyTerms = _b[1];
    var _c = __read(useState(defaultInformationProcessing), 2), informationProcessing = _c[0], setInformationProcessing = _c[1];
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var reset = function () {
        setPrivacyTerms(defaultPrivacyTerms);
        setInformationProcessing(defaultInformationProcessing);
    };
    var sendRequest = function (data) {
        setLoading(true);
        // const formData = new FormData()
        // formData.append("resume", data.resume);
        console.log(data);
        API.contactUs(data)
            .then(function (responce) {
            var message = responce.data.message;
            successNotification(message);
            hideModal();
            reset();
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    var form = useForm({
        mode: 'all',
        defaultValues: defaultFormValues
    });
    var handleSubmit = form.handleSubmit, _e = form.formState, isValid = _e.isValid, submitCount = _e.submitCount;
    var onSubmit = function (data) {
        setPrivacyTerms(function (prev) { return (__assign(__assign({}, prev), { isTouched: true })); });
        setInformationProcessing(function (prev) { return (__assign(__assign({}, prev), { isTouched: true })); });
        if (!loading && privacyTerms.selected && informationProcessing.selected) {
            sendRequest(data);
        }
    };
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit), className: "dialog-form w-full mt-3" }, { children: [_jsxs("div", { children: [_jsx(NameField, { form: form }), _jsx(SurnameField, { form: form }), _jsx(EmailField, { form: form }), _jsx(QuestionField, { form: form }), _jsx(PrivacyPolicy, { informationProcessing: informationProcessing, setInformationProcessing: setInformationProcessing, privacyTerms: privacyTerms, setPrivacyTerms: setPrivacyTerms })] }), _jsx("div", __assign({ className: "w-full flex justify-content-end mt-3 gap-3" }, { children: _jsx(Button, { className: "send-resume w-full app-button app-bg-color text-white", loading: loading, label: translater("contactUsDialogFormButton").toString(), disabled: submitCount > 0 && !isValid }) }))] })));
};
