import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, Outlet } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
export var AdminRoute = function () {
    var token = localStorage.getItem('token');
    var adminPermission = false;
    if (token) {
        var decoded = jwt_decode(token);
        var role = decoded.roles.find(function (role) { return role.title === 'Administrator'; });
        adminPermission = !!role;
    }
    return adminPermission ? _jsx(Outlet, {}) : _jsx(Navigate, { to: '/login' });
};
