var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { setCzechLanguage, setEnglishLanguage, } from "../../../redux/actions/languageActions";
export var Translater = function () {
    var lang = useSelector(function (state) { return state.language; }).lang;
    var changeLanguage = function () {
        if (lang === 'cs')
            return setEnglishLanguage();
        return setCzechLanguage();
    };
    return (_jsx("button", __assign({ onClick: changeLanguage, className: "border-none uppercase app-font-bold text-sm app-color", style: { background: "none" } }, { children: lang === 'cs' ? 'ua' : lang })));
};
