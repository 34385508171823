var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { images } from "../../../../../../assets/images/imgs";
import { translater } from "../../../../../../utils/localization/localization";
export var Type = function (_a) {
    var _b = _a.form, getValues = _b.getValues, setValue = _b.setValue, trigger = _b.trigger;
    var setType = function (gender) {
        setValue('type', gender);
        trigger('type');
    };
    return (_jsxs("div", __assign({ className: "client-type-toggle app-elements-height" }, { children: [_jsxs("div", __assign({ onClick: function () { return setType('Student'); }, className: clsx('client-type', {
                    'active': getValues('type') === 'Student'
                }) }, { children: [_jsx("img", { src: images.home.type[getValues('type') === 'Student' ? 'activeTypeStudent' : 'unactiveTypeStudent'], alt: "male" }), _jsx("span", { children: translater("introductionPageFormTypeToggleStudent") })] })), _jsxs("div", __assign({ onClick: function () { return setType('Company'); }, className: clsx('client-type', {
                    'active': getValues('type') === 'Company'
                }) }, { children: [_jsx("img", { src: images.home.type[getValues('type') === 'Company' ? 'activeTypeCompany' : 'unactiveTypeCompany'], alt: "female" }), _jsx("span", { children: translater("introductionPageFormTypeToggleCompany") })] }))] })));
};
