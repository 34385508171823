var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { translater } from "../../../utils/localization/localization";
import { NewsPreview } from "./components/previews/NewsPreview";
import { useNavigate } from "react-router-dom";
import { API } from "../../../api";
export var News = function () {
    var _a = __read(useState([]), 2), news = _a[0], setNews = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var navigate = useNavigate();
    var loadNews = function () {
        setLoading(true);
        API.getNews()
            .then(function (response) {
            var data = response.data;
            setNews(data);
            setLoading(false);
        })
            .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        loadNews();
    }, []);
    if (loading)
        return _jsx(NewsPreview, {});
    if (news.length === 0) {
        return (_jsx("div", __assign({ className: "w-full flex align-items-center justify-content-center" }, { children: _jsx("span", __assign({ className: "app-gray-color" }, { children: translater("noNewsMessage") })) })));
    }
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "w-full flex flex-wrap gap-4 justify-content-center align-items-stretch" }, { children: news.map(function (newsItem) {
                return (_jsxs("div", __assign({ onClick: function () { return navigate('/current-news/' + newsItem.id); }, className: "flex flex-column gap-3 align-items-center justify-content-center cursor-pointer shadow-1 p-4 w-18rem border-round-lg app-hover" }, { children: [_jsx("img", { src: newsItem.img, alt: "news-image", className: "max-w-12rem" }), _jsx("span", __assign({ className: "app-gray-color text-sm" }, { children: newsItem.date })), _jsx("span", __assign({ className: "max-w-20rem text-center app-font-bold app-header-color" }, { children: newsItem.title }))] }), newsItem.id));
            }) })) }));
};
