var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Parser from "html-react-parser";
import { translater } from "../../../../utils/localization/localization";
import { API } from "../../../../api";
import { NewsItemPreview } from "./previews/NewsItemPreview";
export var NewsItem = function () {
    var _a = __read(useState(null), 2), news = _a[0], setNews = _a[1];
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var navigate = useNavigate();
    var id = useParams().id;
    var loadNews = function () {
        setLoading(true);
        if (id)
            API.getNewsById(id)
                .then(function (response) {
                var data = response.data;
                setNews(data);
                setLoading(false);
            })
                .catch(function () { return setLoading(false); });
    };
    useEffect(function () {
        loadNews();
    }, [id]);
    if (loading)
        return _jsx(NewsItemPreview, {});
    if (!news) {
        return (_jsx("div", __assign({ className: "w-full flex align-items-center justify-content-center" }, { children: _jsx("span", __assign({ className: "app-gray-color" }, { children: translater("noNewsItemMessage") })) })));
    }
    return (_jsxs("div", __assign({ className: "w-full flex-column align-items-center justify-content-center" }, { children: [_jsx("span", __assign({ className: "text-center text-xl app-color flex align-items-center justify-content-center" }, { children: news.title })), _jsx("div", __assign({ className: "w-full news-content" }, { children: Parser(news.content) }))] })));
};
