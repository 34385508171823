import { jsx as _jsx } from "react/jsx-runtime";
import YouTube from 'react-youtube';
import '../../../../../assets/styles/scss/particles/youtube-video.scss';
export var YouTubeVideo = function (_a) {
    var videoId = _a.videoId;
    var opts = {
        height: '395',
        width: '645',
    };
    return (_jsx(YouTube, { videoId: videoId, className: 'youtube-video border-round-lg', opts: opts }));
};
