var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FileUpload } from 'primereact/fileupload';
import { translater } from "../../../../../../../utils/localization/localization";
import { ErrorBlock } from "../../../../../../components/particals/error/ErrorBlock";
var validate = function (value) {
    if (!value) {
        return translater("bootcampPageResumeFormEmptyValidate").toString();
    }
    return true;
};
export var ResumeField = function (_a) {
    var _b = _a.form, register = _b.register, errors = _b.formState.errors, setValue = _b.setValue, trigger = _b.trigger;
    var validations = { validate: validate };
    var onUpload = function (event) {
        var files = event.files;
        var _a = __read(files, 1), file = _a[0];
        if (file) {
            setValue("resume", file);
            setTimeout(function () { return trigger("resume"); }, 0);
        }
    };
    var onClear = function () {
        setValue("resume", null);
        setTimeout(function () { return trigger("resume"); }, 0);
    };
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "resume", className: "block text-900 font-medium mb-2" }, { children: translater("bootcampPageResumeFormResumeField") })), _jsx(FileUpload, __assign({}, register('resume', validations), { id: "resume", mode: "basic", name: "resume", accept: ".pdf", chooseLabel: translater("bootcampPageResumeFormResumeFieldChooseLabel").toString(), onSelect: onUpload, onClear: onClear, contentClassName: "font-bold", className: "w-full mb-3 app-font" })), _jsx(ErrorBlock, { errors: errors, keyword: "resume" })] }));
};
