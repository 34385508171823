var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { translater } from "../../../../../../utils/localization/localization";
import { images } from "../../../../../../assets/images/imgs";
var validate = function (value, form) {
    if (form.type === 'Company' && !value)
        return translater("introductionPageFormCompanyNameFieldValidate").toString();
    if (form.type === 'Company' && !value.trim())
        return translater("introductionPageFormCompanyNameFieldValidate").toString();
    return true;
};
export var CompanyName = function (_a) {
    var _b;
    var _c = _a.form, register = _c.register, errors = _c.formState.errors;
    var validations = { validate: validate };
    return (_jsx("input", __assign({}, register('companyName', validations), { style: { backgroundImage: "url(".concat(images.signUp.form.companyNameFieldIcon, ")") }, className: "app-elements-height name company-field ".concat(errors && ((_b = errors.companyName) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : ''), type: "text", name: "companyName", placeholder: translater("introductionPageFormCompanyNameFieldPlaceholder").toString() })));
};
