import { combineReducers } from 'redux';
import { appReducer } from './appReducer';
import { authReducer } from './authReducer';
import { languagesReducer } from './languageReducer';
import { notificationsReducer } from './notificationsReducer';
import { themesReducer } from './themeReducer';
export var rootReducer = combineReducers({
    language: languagesReducer,
    theme: themesReducer,
    notifications: notificationsReducer,
    auth: authReducer,
    app: appReducer
});
