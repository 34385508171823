var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from "./components/Form";
import '../../../../assets/styles/scss/pages/admin/login.scss';
export var Login = function () {
    return (_jsxs("main", __assign({ className: "relative system-settings flex flex-column align-items-center justify-content-center mt-5" }, { children: [_jsx("h1", { children: "System settings" }), _jsx(Form, {})] })));
};
