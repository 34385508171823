var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import ReactQuill from 'react-quill';
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
import { editorModules } from "./CreateButton";
export var UpdateButton = function (_a) {
    var loadNews = _a.loadNews, newsItem = _a.news;
    var _b = __read(useState(false), 2), dialogVisible = _b[0], setDialogVisible = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(newsItem), 2), news = _d[0], setNews = _d[1];
    var save = function () {
        setLoading(true);
        API.updateNews(news)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setDialogVisible(false);
            setLoading(false);
            loadNews();
        })
            .catch(function () { return setLoading(false); });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { icon: "pi pi-pencil", className: "custom-target-icon-update-extension p-button p-component p-button-icon-only p-button-outlined border-circle", style: { width: "50px", height: "50px" }, onClick: function () {
                    setDialogVisible(true);
                } }), _jsx(Dialog, __assign({ header: "Update news", visible: dialogVisible, modal: true, resizable: true, maximizable: true, headerClassName: "surface-50", contentClassName: "surface-50", className: 'form-dialog-window', contentStyle: { minHeight: '400px' }, style: { minWidth: '800px' }, onHide: function () { return setDialogVisible(false); } }, { children: _jsxs("div", __assign({ className: "flex flex-column mt-1 w-full gap-2" }, { children: [_jsx("label", { children: "Title" }), _jsx(InputText, { className: "w-full", placeholder: "Title", value: news.title, onChange: function (e) { return setNews(function (prev) { return (__assign(__assign({}, prev), { title: e.target.value })); }); } }), _jsx("label", { children: "Image link" }), _jsx(InputText, { className: "w-full", placeholder: "Image link", value: news.img, onChange: function (e) { return setNews(function (prev) { return (__assign(__assign({}, prev), { img: e.target.value })); }); } }), _jsx("label", { children: "Date" }), _jsx(InputText, { className: "w-full", placeholder: "Date", value: news.date, onChange: function (e) { return setNews(function (prev) { return (__assign(__assign({}, prev), { date: e.target.value })); }); } }), _jsx("label", { children: "Webpage content" }), _jsx(ReactQuill, { theme: "snow", modules: editorModules, value: news.content, onChange: function (value) { return setNews(function (prev) { return (__assign(__assign({}, prev), { content: value })); }); } }), _jsx(Button, { label: "Save news", disabled: loading, className: "app-bg-color app-font-bold app-border-radius text-white border-none", onClick: function () { return save(); } })] })) }))] }));
};
