import axios from "axios";
var getWebsiteUrl = function () {
    var website = "".concat(location.origin, "/api");
    if (website.includes('localhost'))
        return 'http://localhost:4004/api';
    return website;
};
var urlPath = getWebsiteUrl();
var path = function (path) { return urlPath + path; };
// AUTH
var hubspotLogin = function (data) {
    return axios.post(path('/auth/hubspot-login'), data);
};
var login = function (data) {
    return axios.post(path('/auth/login'), data);
};
// CONTENT
var getLanguages = function () {
    return axios.get(path('/content/languages'));
};
var setLanguages = function (data) {
    return axios.put(path('/content/languages/update'), data);
};
// FEEDBACK
var uploadResume = function (data) {
    return axios.post(path('/feedback/resume'), data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
var contactUs = function (data) {
    return axios.post(path('/feedback/contact-us'), data);
};
// NEWS
var getNews = function () {
    return axios.get(path('/news'));
};
var getCurrentNewsAmount = function () {
    return axios.get(path('/news/current-news-amount'));
};
var resaveNewsOrder = function (ids) {
    return axios.post(path('/news/resave-news-order'), { ids: ids });
};
var createNews = function (data) {
    return axios.post(path('/news/create'), data);
};
var updateNews = function (data) {
    return axios.post(path('/news/update'), data);
};
var removeNews = function (id) {
    return axios.post(path('/news/remove'), { id: id });
};
var getNewsById = function (id) {
    return axios.post(path('/news/get-news'), { id: id });
};
export var API = {
    hubspotLogin: hubspotLogin,
    getLanguages: getLanguages,
    getNewsById: getNewsById,
    createNews: createNews,
    updateNews: updateNews,
    removeNews: removeNews,
    uploadResume: uploadResume,
    contactUs: contactUs,
    login: login,
    setLanguages: setLanguages,
    getNews: getNews,
    getCurrentNewsAmount: getCurrentNewsAmount,
    resaveNewsOrder: resaveNewsOrder
};
