var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
import ImageResize from 'quill-image-resize-module-react';
import BlotFormatter from 'quill-blot-formatter';
if (Quill) {
    Quill.register('modules/imageResize', ImageResize);
    Quill.register('modules/blotFormatter', BlotFormatter);
}
export var editorModules = {
    toolbar: {
        container: [
            ["bold", "italic", "underline"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["link", "image"], // Вставка ссылок, изображений и видео
            // ["insertImageUrl"] // Добавим кнопку для вставки изображения по URL
        ],
        handlers: {
            'image': function () {
                var url = prompt('Enter image URL');
                if (url) {
                    console.log(url);
                    var range = this.quill.getSelection();
                    this.quill.insertEmbed(range.index, 'image', url);
                }
            }
        }
    },
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    },
    blotFormatter: {},
};
var defaultNewsData = {
    title: "",
    img: "",
    date: "",
    content: "",
};
export var CreateButton = function (_a) {
    var loadNews = _a.loadNews;
    var _b = __read(useState(false), 2), dialogVisible = _b[0], setDialogVisible = _b[1];
    var _c = __read(useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(useState(defaultNewsData), 2), news = _d[0], setNews = _d[1];
    var save = function () {
        setLoading(true);
        API.createNews(news)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            closeDialog();
            setLoading(false);
            loadNews();
        })
            .catch(function () { return setLoading(false); });
    };
    var closeDialog = function () {
        setDialogVisible(false);
        setNews(defaultNewsData);
    };
    // const setCurrentNewsAmount = () => {
    //   API.getCurrentNewsAmount()
    //     .then((response: AxiosResponse<{ amount: number }>) => {
    //       const {
    //         data: { amount },
    //       } = response;
    //       console.log(response)
    //       setNews((prev) => ({ ...prev, order: amount }))
    //     })
    //     .catch(() => setLoading(false))
    // }
    return (_jsxs(_Fragment, { children: [_jsx(Button, { label: "Create news", className: "app-bg-color app-font-bold app-border-radius text-white border-none", onClick: function () {
                    setDialogVisible(true);
                    // setCurrentNewsAmount()
                } }), _jsx(Dialog, __assign({ header: "Create news", visible: dialogVisible, modal: true, resizable: true, maximizable: true, headerClassName: "surface-50", contentClassName: "surface-50", className: "form-dialog-window", contentStyle: { minHeight: "400px" }, style: { minWidth: "800px" }, onHide: function () { return closeDialog(); } }, { children: _jsxs("div", __assign({ className: "flex flex-column mt-1 w-full gap-2" }, { children: [_jsx("label", { children: "Title" }), _jsx(InputText, { className: "w-full", placeholder: "Title", value: news.title, onChange: function (e) {
                                return setNews(function (prev) { return (__assign(__assign({}, prev), { title: e.target.value })); });
                            } }), _jsx("label", { children: "Image link" }), _jsx(InputText, { className: "w-full", placeholder: "Image link", value: news.img, onChange: function (e) {
                                return setNews(function (prev) { return (__assign(__assign({}, prev), { img: e.target.value })); });
                            } }), _jsx("label", { children: "Date" }), _jsx(InputText, { className: "w-full", placeholder: "Date", value: news.date, onChange: function (e) {
                                return setNews(function (prev) { return (__assign(__assign({}, prev), { date: e.target.value })); });
                            } }), _jsx("label", { children: "Webpage content" }), _jsx(ReactQuill, { theme: "snow", value: news.content, modules: editorModules, onChange: function (value) {
                                return setNews(function (prev) { return (__assign(__assign({}, prev), { content: value })); });
                            } }), _jsx(Button, { label: "Save news", disabled: loading, className: "app-bg-color app-font-bold app-border-radius text-white border-none", onClick: function () { return save(); } })] })) }))] }));
};
