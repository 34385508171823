import { SET_LANGUAGE } from "../reducers/languageReducer";
import { store } from "../store";
export var setEnglishLanguage = function () {
    var language = {
        type: SET_LANGUAGE,
        payload: 'en'
    };
    localStorage.setItem('app-language', 'en');
    return store.dispatch(language);
};
export var setCzechLanguage = function () {
    var language = {
        type: SET_LANGUAGE,
        payload: 'cs'
    };
    localStorage.setItem('app-language', 'cs');
    return store.dispatch(language);
};
