var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { NavLink, useLocation } from "react-router-dom";
import { translater } from "../../../../../utils/localization/localization";
import { classNames } from "primereact/utils";
export var Menu = function () {
    var location = useLocation();
    var menuItems = [
        {
            path: '/',
            title: translater("headerMenuIntroduction").toString()
        },
        {
            path: '/students',
            title: translater("headerMenuStudents").toString()
        },
        {
            path: '/companies',
            title: translater("headerMenuCompanies").toString()
        },
        {
            path: '/muni',
            title: translater("headerMenuMuni").toString()
        },
        {
            path: '/bootcamp',
            title: translater("headerMenuBootcamp").toString()
        },
        {
            path: '/news',
            title: translater("headerMenuNews").toString()
        },
        {
            path: '/support-for-ukrainian-students',
            title: translater("headerStudentsSupport").toString()
        },
    ];
    return (_jsx(_Fragment, { children: ['/sign-up', '/privacy-policy', '/login', '/panel', '/news-list'].includes(location.pathname)
            ? ''
            : (_jsx("ul", __assign({ className: "menu flex mt-5" }, { children: menuItems.map(function (item, index) {
                    return (_jsx("li", { children: _jsx(NavLink, __assign({ to: item.path, className: "app-hover" }, { children: _jsx("span", __assign({ className: classNames("font-medium app-header-color font-bold cursor", {
                                    'student-support-menu-item': item.path === '/support-for-ukrainian-students'
                                }) }, { children: item.title })) })) }, index));
                }) }))) }));
};
