var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NotFound } from "./not-found/NotFound";
import { Home } from "./home/Home";
import { PrivacyPolicy } from "./privacy-policy/PrivacyPolicy";
import { SignUp } from "./sign-up/SignUp";
import { Muni } from "./muni/Muni";
import { Companies } from "./companies/Companies";
import { Students } from "./students/Students";
import { MainLayout } from "../layouts/MainLayout";
import { HomePageLayout } from "../layouts/HomePageLayout";
import { Bootcamp } from "./bootcamp/Bootcamp";
import { AdminRoute } from "../components/particals/AdminRoute";
import { Editor } from "../admin/pages/editor/Editor";
import { Login } from "../admin/pages/login/Login";
import { News } from "./news/News";
import { NewsItem } from "./news/components/NewsItem";
import { NewsList } from "../admin/pages/news/news-list/NewsList";
import { StudentsSupport } from "./students-support/StudentsSupport";
export var Pages = function () {
    var auth = useSelector(function (state) { return state.auth; }).isAuthenticated;
    return (_jsxs(Routes, { children: [_jsx(Route, __assign({ path: "/", element: _jsx(HomePageLayout, {}) }, { children: _jsx(Route, { index: true, element: _jsx(Home, {}) }) })), _jsxs(Route, __assign({ path: "/", element: _jsx(MainLayout, {}) }, { children: [_jsx(Route, { path: 'login', element: auth ? _jsx(Navigate, { to: '/' }) : _jsx(Login, {}) }), _jsx(Route, { path: "sign-up", element: _jsx(SignUp, {}) }), _jsx(Route, { path: "privacy-policy", element: _jsx(PrivacyPolicy, {}) }), _jsx(Route, { path: "companies", element: _jsx(Companies, {}) }), _jsx(Route, { path: "students", element: _jsx(Students, {}) }), _jsx(Route, { path: "muni", element: _jsx(Muni, {}) }), _jsx(Route, { path: "bootcamp", element: _jsx(Bootcamp, {}) }), _jsx(Route, { path: "news", element: _jsx(News, {}) }), _jsx(Route, { path: "support-for-ukrainian-students", element: _jsx(StudentsSupport, {}) }), _jsx(Route, { path: "current-news/:id", element: _jsx(NewsItem, {}) })] })), _jsx(Route, __assign({ path: '/', element: _jsx(AdminRoute, {}) }, { children: _jsxs(Route, __assign({ path: "/", element: _jsx(MainLayout, {}) }, { children: [_jsx(Route, { path: "panel", element: _jsx(Editor, {}) }), _jsx(Route, { path: "news-list", element: _jsx(NewsList, {}) })] })) })), _jsx(Route, { path: "*", element: _jsx(NotFound, {}) })] }));
};
