var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "primereact/button";
import { useState } from "react";
import { API } from "../../../../../../api";
import { successNotification } from "../../../../../../redux/actions/notificationsActions";
export var RemoveButton = function (_a) {
    var loadNews = _a.loadNews, newsItem = _a.news;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(newsItem), 2), news = _c[0], setNews = _c[1];
    var remove = function () {
        setLoading(true);
        API.removeNews(news.id)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
            loadNews();
        })
            .catch(function () { return setLoading(false); });
    };
    return (_jsx(_Fragment, { children: _jsx(Button, { icon: "pi pi-trash", className: "custom-target-icon-delete-extension p-button p-component  p-button-icon-only p-button-outlined p-button-danger border-circle", disabled: loading, style: { width: "50px", height: "50px" }, onClick: function () { return remove(); } }) }));
};
