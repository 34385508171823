var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Email } from "./fields/Email";
import { Name } from "./fields/Name";
import { Gender } from "./fields/Gender";
import { Surname } from "./fields/Surname";
import { API } from "../../../../../api/index";
import { translater } from "../../../../../utils/localization/localization";
import { PrivacyPolicy } from "./fields/PrivacyPolicy";
import { Type } from "./fields/Type";
import { CompanyName } from "./fields/CompanyName";
import { CompanyScope } from "./fields/CompanyScope";
var defaultFormValues = {
    name: '',
    surname: '',
    email: '',
    gender: 'Male',
    type: 'Student',
    companyName: '',
    companyScope: ''
};
var defaultPrivacyTerms = { selected: false, isTouched: false };
var defaultInformationProcessing = { selected: false, isTouched: false };
export var Form = function (_a) {
    var showDoneInterface = _a.showDoneInterface;
    var _b = __read(useState(defaultPrivacyTerms), 2), privacyTerms = _b[0], setPrivacyTerms = _b[1];
    var _c = __read(useState(defaultInformationProcessing), 2), informationProcessing = _c[0], setInformationProcessing = _c[1];
    var _d = __read(useState(false), 2), loading = _d[0], setLoading = _d[1];
    var location = useLocation();
    var reset = function () {
        setPrivacyTerms(defaultPrivacyTerms);
        setInformationProcessing(defaultInformationProcessing);
    };
    var sendData = function (values) {
        setLoading(true);
        API.hubspotLogin(values)
            .then(function (response) {
            setLoading(false);
            showDoneInterface();
            window.scrollTo(0, 0);
            reset();
        })
            .catch(function (error) {
            setLoading(false);
        });
    };
    var form = useForm({
        mode: 'all',
        defaultValues: defaultFormValues
    });
    var handleSubmit = form.handleSubmit, _e = form.formState, isValid = _e.isValid, submitCount = _e.submitCount, getValues = form.getValues, setValue = form.setValue, trigger = form.trigger;
    var onSubmit = function (data) {
        setPrivacyTerms(function (prev) { return (__assign(__assign({}, prev), { isTouched: true })); });
        setInformationProcessing(function (prev) { return (__assign(__assign({}, prev), { isTouched: true })); });
        if (!loading && privacyTerms.selected && informationProcessing.selected) {
            sendData(data);
        }
    };
    useEffect(function () {
        var searchParams = new URLSearchParams(location.search);
        if (searchParams.has('type')) {
            setValue('type', 'Company');
            setTimeout(function () { return trigger('type'); }, 0);
        }
    }, [location]);
    return (_jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("span", __assign({ className: "description" }, { children: [translater("introductionPageFormDescription"), _jsx(Link, __assign({ to: '/' }, { children: translater('introductionPageFormDescriptionLink') }))] })), _jsxs("div", __assign({ className: "line" }, { children: [_jsx(Name, { form: form }), _jsx(Surname, { form: form })] })), _jsx(Email, { form: form }), _jsxs("div", __assign({ className: "line flex-column" }, { children: [_jsx(Type, { form: form }), getValues('type') === 'Student' ? (_jsx(Gender, { form: form })) : (_jsxs("div", __assign({ className: "line" }, { children: [_jsx(CompanyName, { form: form }), _jsx(CompanyScope, { form: form })] })))] })), _jsx(Button, { loadingIcon: "", loading: loading, className: clsx('send-button app-elements-height bold-button-span', {
                    'loading': loading,
                    'disabled': submitCount > 0 && !isValid
                }), label: translater("introductionPageFormSendButton").toString() }), _jsx(PrivacyPolicy, { informationProcessing: informationProcessing, setInformationProcessing: setInformationProcessing, privacyTerms: privacyTerms, setPrivacyTerms: setPrivacyTerms })] })));
};
