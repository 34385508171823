var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { motion } from 'framer-motion';
import { translater } from '../../../../utils/localization/localization';
import { images } from '../../../../assets/images/imgs';
import '../../../../assets/styles/scss/pages/successfully-saved.scss';
export var SuccessfullySaved = function (_a) {
    var hideDoneInterface = _a.hideDoneInterface;
    return (_jsxs(motion.div, __assign({ initial: { opacity: 0, scale: 0.5 }, animate: { opacity: 1, scale: 1 }, transition: { duration: 0.6 }, className: "successfully-saved" }, { children: [_jsx("img", { src: images.successfullySaved.success, alt: "success" }), _jsx("h3", __assign({ className: 'title m-0' }, { children: translater("introductionPageSuccessfullySaved") })), _jsx("span", __assign({ className: 'message app-gray-color' }, { children: translater("introductionPageSuccessfullySavedMessage") })), _jsx("button", __assign({ onClick: function () { return hideDoneInterface(); } }, { children: translater("introductionPageSuccessfullySavedButton") }))] })));
};
