var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { API } from "../../../../api";
import { logout } from "../../../../redux/actions/authActions";
import { successNotification } from "../../../../redux/actions/notificationsActions";
import { Menu } from "../../components/Menu";
export var Editor = function () {
    var navigate = useNavigate();
    var _a = __read(useState(false), 2), loading = _a[0], setLoading = _a[1];
    var _b = __read(useState(''), 2), activeBoxKey = _b[0], setActiveBoxKey = _b[1];
    var _c = __read(useState(''), 2), finder = _c[0], setFinder = _c[1];
    var toast = useRef(null);
    var _d = __read(useState({ cs: null, en: null }), 2), translation = _d[0], setTranslation = _d[1];
    var _e = __read(useState([]), 2), keys = _e[0], setKeys = _e[1];
    var signOut = function () {
        logout();
        navigate('/');
    };
    useEffect(function () {
        var loadLanguages = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, dirtyKeys, filtedKeys;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, API.getLanguages()];
                    case 1:
                        data = (_a.sent()).data;
                        setTranslation(data);
                        dirtyKeys = Object.keys(data.en);
                        filtedKeys = dirtyKeys.filter(function (key) { return typeof data.en[key] === 'string'; });
                        setKeys(filtedKeys);
                        return [2 /*return*/];
                }
            });
        }); };
        loadLanguages();
    }, []);
    var setEnglishUpdate = function (key, value) {
        setActiveBoxKey(key);
        var translationClone = JSON.parse(JSON.stringify(translation.en));
        translationClone[key] = value;
        setTranslation((function (prev) { return (__assign(__assign({}, prev), { en: translationClone })); }));
    };
    var setCzechUpdate = function (key, value) {
        setActiveBoxKey(key);
        var translationClone = JSON.parse(JSON.stringify(translation.cs));
        translationClone[key] = value;
        setTranslation((function (prev) { return (__assign(__assign({}, prev), { cs: translationClone })); }));
    };
    var saveData = function (data) {
        setLoading(true);
        API.setLanguages(data)
            .then(function (response) {
            var message = response.data.message;
            successNotification(message);
            setLoading(false);
        })
            .catch(function (error) { return setLoading(false); });
    };
    var saveChanges = function () {
        confirmDialog({
            className: "w-30rem",
            message: 'I am sure that all the data I entered is correct. I closed all the markup tags and checked the correct spelling of the text.',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: function () { return saveData(translation); },
            reject: function () { }
        });
    };
    return (_jsxs("main", __assign({ className: "flex flex-column gap-4" }, { children: [_jsx(Menu, {}), _jsxs("nav", __assign({ className: "w-full flex justify-content-between align-items-center gap-2" }, { children: [_jsx(Button, { style: { whiteSpace: 'nowrap' }, className: "p-button-success p-button-sm w-13rem app-button-colors", onClick: saveChanges, loading: loading, label: "Save Changes" }), _jsxs("span", __assign({ className: "p-input-icon-left w-full" }, { children: [_jsx("i", { className: "pi pi-search" }), _jsx(InputText, { value: finder, onChange: function (e) { return setFinder(e.target.value); }, placeholder: "Search in EN and CS", className: "p-inputtext-sm w-full app-font" })] })), _jsx(Button, { style: { whiteSpace: 'nowrap' }, className: "p-button-outlined p-button-secondary p-button-sm w-8rem", onClick: signOut, label: "Log out" })] })), _jsx("article", __assign({ className: "flex flex-column gap-5 pl-4" }, { children: keys
                    .filter(function (key) {
                    var enValue = translation.en ? translation.en[key] : '';
                    var csValue = translation.cs ? translation.cs[key] : '';
                    var finderIncluding = enValue.toLowerCase().includes(finder.toLowerCase())
                        || csValue.toLowerCase().includes(finder.toLowerCase())
                        || key.toLowerCase().includes(finder.toLowerCase());
                    var isKeyMaching = key === activeBoxKey;
                    return finderIncluding || isKeyMaching;
                })
                    .map(function (key, index) {
                    return (_jsxs("section", __assign({ className: "flex flex-column gap-2" }, { children: [_jsx("span", __assign({ className: "app-color app-font-bold" }, { children: key })), _jsxs("div", __assign({ className: "editor-area" }, { children: [_jsx("span", __assign({ className: "editor-area-label" }, { children: "EN" })), _jsx(InputTextarea, { className: "editor-textarea en-editor-textarea", placeholder: "EN", value: translation.en ? translation.en[key] : '', onChange: function (e) { return setEnglishUpdate(key, e.target.value); }, rows: 2 })] })), _jsxs("div", __assign({ className: "editor-area" }, { children: [_jsx("span", __assign({ className: "editor-area-label" }, { children: "CS" })), _jsx(InputTextarea, { placeholder: "CS", className: "editor-textarea cs-editor-textarea", value: translation.cs ? translation.cs[key] : '', onChange: function (e) { return setCzechUpdate(key, e.target.value); }, rows: 2 })] }))] }), index));
                }) })), _jsx(Toast, { ref: toast }), _jsx(ConfirmDialog, {})] })));
};
