var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { images } from "../../../../../../assets/images/imgs";
import { translater } from "../../../../../../utils/localization/localization";
import clsx from "clsx";
export var Gender = function (_a) {
    var _b = _a.form, getValues = _b.getValues, setValue = _b.setValue, trigger = _b.trigger;
    var setGender = function (gender) {
        setValue('gender', gender);
        trigger('gender');
    };
    return (_jsxs("div", __assign({ className: "sex-toggle app-elements-height" }, { children: [_jsxs("div", __assign({ onClick: function () { return setGender('Male'); }, className: clsx('sex', {
                    'active': getValues('gender') === 'Male'
                }) }, { children: [_jsx("img", { src: images.home.sex.male[getValues('gender') === 'Male' ? 'active' : 'unactive'], alt: "male" }), _jsx("span", { children: translater("introductionPageFormSexToggleMale") })] })), _jsxs("div", __assign({ onClick: function () { return setGender('Female'); }, className: clsx('sex', {
                    'active': getValues('gender') === 'Female'
                }) }, { children: [_jsx("img", { src: images.home.sex.female[getValues('gender') === 'Female' ? 'active' : 'unactive'], alt: "female" }), _jsx("span", { children: translater("introductionPageFormSexToggleFemale") })] })), _jsx("div", __assign({ onClick: function () { return setGender('Diverse'); }, className: clsx('sex', {
                    'active': getValues('gender') === 'Diverse'
                }) }, { children: _jsx("span", { children: translater("introductionPageFormSexToggleDiverse") }) }))] })));
};
