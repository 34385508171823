var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { translater } from "../../../utils/localization/localization";
import '../../../assets/styles/scss/pages/bootcamp.scss';
import { YouTubeVideo } from "../../components/particals/video/youtube/YouTubeVideo";
import { setCzechLanguage } from "../../../redux/actions/languageActions";
export var StudentsSupport = function () {
    var _a = __read(useState(false), 2), dialogVisible = _a[0], setDialogVisible = _a[1];
    useEffect(function () {
        setCzechLanguage();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("h1", __assign({ className: "bootcamp-title app-header-color text-4xl text-center app-font-bold m-0 ms-auto" }, { children: translater("studentsSupportPageTitle") })), _jsx("h4", __assign({ className: "bootcamp-description app-header-color text-center mt-3 ms-auto w-9 mb-4" }, { children: translater("studentsSupportPageDescription") })), _jsx("article", __assign({ className: "phases-block" }, { children: _jsx("section", __assign({ className: "flex w-full gap-5 align-items-center justify-content-between" }, { children: _jsx("span", __assign({ className: 'app-button app-bg-color text-white px-5 mx-auto text-base cursor-pointer', onClick: function () { return window.open(String(translater("studentsSupportPageApplyButtonLink"))); } }, { children: translater("studentsSupportPageApplyButton") })) })) })), _jsx("article", __assign({ className: "app-content flex flex-column justify-content-center align-items-center" }, { children: _jsx(YouTubeVideo, { videoId: translater("studentsSupportPageVideoLink").toString() }) })), _jsx("article", __assign({ className: "phases-block" }, { children: _jsx("section", __assign({ className: "flex gap-5 align-items-center justify-content-between mb-5" }, { children: _jsx("div", __assign({ className: "flex flex-column result-block" }, { children: [
                            'studentsSupportPageInfoBlock',
                            'studentsSupportPageContact',
                        ].map(function (translate) {
                            return (_jsx(motion.div, __assign({ initial: { opacity: 0, scale: 0.5 }, animate: { opacity: 1, scale: 1 }, transition: { duration: 0.6 }, className: "mt-4 flex align-items-start gap-3" }, { children: _jsx("span", __assign({ className: "app-gray-color app-line-height" }, { children: translater(translate) })) })));
                        }) })) })) }))] }));
};
